import React, { useState, useContext, useMemo } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { BlathersContext } from "@/contexts/Blathers"

import BlathersLayout from "@/components/BlathersLayout"
import InsectCard from "@/components/InsectCard"
import NoMatch from "@/components/NoMatch"
import { Tabs,MultiLineTabs, TextTab } from "@/components/Tabs"

import createNodeId from "@/utils/createNodeId"

const BlathersMuseumPage = () => {
  const queryData = useStaticQuery(graphql`
    query {
      allBlathersYaml {
        nodes {
          fishes {
            name {
              en
              ja
              ko
              zh_hans
              zh_hant
            }
            icon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            bookIcon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            furnitureIcon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            modelIcon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            monthOfYear {
              north
              south
            }
          }
        }
      }
    }
  `)
  const { fishes } = queryData.allBlathersYaml.nodes[0]

  const formatFishes = fishes.map((fish) => ({
    ...fish,
    isDonated: false,
    id: createNodeId(fish.name.en, `acnh`),
  }))

  const [value, setValue] = useState(0)

  const [value2, setValue2] = useState(0)

  const [value3, setValue3] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChange2 = (event, newValue) => {
    setValue2(newValue)
  }

  const handleChange3 = (event, newValue) => {
    setValue3(newValue)
  }

  return (
    <BlathersLayout>
      <MultiLineTabs value={value} onChange={handleChange}>
        <TextTab label="nh" />
        <TextTab label="sh" />
      </MultiLineTabs>
      <MultiLineTabs value={value2} onChange={handleChange2}>
        <TextTab label="all" />
        <TextTab label="fishAvailable" />
        <TextTab label="upcoming" />
        <TextTab label="expiring" />
      </MultiLineTabs>
      <MultiLineTabs value={value3} onChange={handleChange3}>
        <TextTab label="all" />
        <TextTab label="notDonated" />
        <TextTab label="donated" />
      </MultiLineTabs>
      <InsectCards
        tabs={{ value, value2, value3 }}
        insects={formatFishes}
        type="fish"
      />
    </BlathersLayout>
  )
}

export default BlathersMuseumPage

const InsectCards = ({ insects, type, tabs }) => {
  const { blathers } = useContext(BlathersContext)

  const currentInsects = useMemo(() => {
    const insectsHasMarked = insects.map((insect) => {
      const insecthasMarked = blathers.filter((id) => id === insect.id)
      if (insecthasMarked.length) {
        return {
          ...insect,
          isDonated: true,
        }
      } else {
        return {
          ...insect,
        }
      }
    })
    let insectsArr = [...insectsHasMarked]
    const { value, value2, value3 } = tabs
    const currentMonth = new Date().getMonth() + 1
    const nextMonth = ((new Date().getMonth() + 1) % 12) + 1
    const hemisphere = value === 0 ? "north" : "south"

    if (value3 === 1) {
      insectsArr = insectsHasMarked.filter((insect) => !insect.isDonated)
    } else if (value3 === 2) {
      insectsArr = insectsHasMarked.filter((insect) => insect.isDonated)
    }

    if (value2 === 1) {
      insectsArr = insectsArr.filter((insect) =>
        insect.monthOfYear[hemisphere].includes(currentMonth)
      )
    } else if (value2 === 2) {
      insectsArr = insectsArr
        .filter((insect) => insect.monthOfYear[hemisphere].includes(nextMonth))
        .filter(
          (insect) => !insect.monthOfYear[hemisphere].includes(currentMonth)
        )
    } else if (value2 === 3) {
      insectsArr = insectsArr
        .filter((insect) =>
          insect.monthOfYear[hemisphere].includes(currentMonth)
        )
        .filter(
          (insect) => !insect.monthOfYear[hemisphere].includes(nextMonth)
        )
    }

    return insectsArr
  }, [blathers, insects, tabs])

  return (
    <StyledInsectCards>
      {currentInsects.length ? (
        <div className="insect_cards">
          {currentInsects.map((insect) => (
            <InsectCard insect={insect} type={type} key={insect.name.en} />
          ))}
        </div>
      ) : (
        <NoMatch />
      )}
    </StyledInsectCards>
  )
}

const StyledInsectCards = styled.div`
  .insect_cards {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px;
    @media (max-width: 450px) {
      padding: 10px 0;
    }
  }
`
